<template>
  <section
    class="
      grid grid-cols-2
      md:grid-cols-[80px_1fr_120px]
      gap-8
      md:gap-x-8
      w-full
      md:items-start
    "
  >
    <div
      class="
        col-start-1 col-span-1
        flex flex-row
        justify-between
      "
    >
      <button class="flex items-center gap-2 group" @click="openFilterModal">
        <FilterIcon
          class="
            w-6
            h-6
            stroke-black
            fill-lightGray
            group-hover:stroke-brandRed
          "
        />
        <span
          class="text-sm tracking-wider lg:text-base group-hover:text-brandRed"
          >{{ $t('Filter') }}</span
        >
      </button>
    </div>
    <div class="col-start-2 md:col-start-3 flex justify-end">
      <span
        class="
          flex
          items-center
          text-sm
          tracking-wider
          lowercase
          lg:text-base
          text-darkGray
        "
        >{{ totalProducts }} {{ $t('Products') }}</span
      >
    </div>
    <ul
      v-if="getSelectedFilters.length"
      class="
        col-span-2
        md:col-start-2 md:col-span-1 md:row-start-1
        text-sm
        md:text-base
        flex flex-row flex-wrap
        gap-8 gap-y-4
      "
    >
      <button
        class="flex flex-row items-center gap-2 cursor-pointer"
        @click="$store.dispatch('elevate/resetAllFilters', { searchRequestId })"
      >
        {{ $t('Filter_Reset') }}
        <ReturnIcon class="w-4 h-4" />
      </button>
      <li
        v-for="(filter, index) in getSelectedFilters"
        :key="index"
        class="flex flex-row gap-2 items-center cursor-pointer"
        @click="toggleFilter(filter)"
      >
        {{ filter.value }}
        <CrossIcon class="w-[10px] h-[10px]" @click="toggleFilter(filter)" />
      </li>
    </ul>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import FilterIcon from '~/assets/icons/filter.svg'
import ReturnIcon from '~/assets/icons/return.svg'
import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'Filterbar',
  components: {
    FilterIcon,
    ReturnIcon,
    CrossIcon,
  },
  props: {
    totalProducts: {
      type: Number,
      default: 0,
    },
    viewedProducts: {
      type: Number,
      default: 0,
    },
    searchRequestId: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('elevate', ['searchResponses']),
    getSelectedFilters() {
      const filtersDisplayed = []
      const selectedFilters =
        this.searchResponses[this.searchRequestId].query.filters

      if (!selectedFilters) {
        return []
      }

      function findLabelForKeyValue(key, value) {
        const filter = this.filters.find((filter) => filter.id === key)
        if (filter) {
          const filterValue = filter.values.find(
            (filterValue) => filterValue.id === value
          )
          if (filterValue) {
            return { key, value: filterValue.label, id: filterValue.id }
          }
        }
        return null
      }

      Object.keys(selectedFilters).forEach((key) => {
        selectedFilters[key].forEach((value) => {
          const filterInfo = findLabelForKeyValue.call(this, key, value)
          if (filterInfo) {
            filtersDisplayed.push(filterInfo)
          }
        })
      })
      return filtersDisplayed
    },
  },
  methods: {
    openFilterModal() {
      this.$store.dispatch('ui/filter/showFilterModal')
    },
    toggleFilter(value) {
      return this.$store.dispatch('elevate/toggleFilter', {
        searchRequestId: this.searchRequestId,
        filter: {
          id: value.key,
          value: value.id,
        },
      })
    },
  },
}
</script>
